import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ThemeProvider from 'react-bootstrap/ThemeProvider'

import { Signup } from "./components/signup/Signup";
import { Login } from "./components/login/Login";
import { ApiPaths, ApplicationAPI } from "./api/api";
import { useLocalStorage } from "./hooks/useLocalStorage";

import './App.scss';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
]);

function App() {
  const [token, setToken] = useLocalStorage<string>('token');

  useEffect(() => {
    const check = async () => {
      const res: any = await ApplicationAPI.get(ApiPaths.Check)
      if (res?.status !== 200) {
        setToken('')
        return
      }
      if (token && process.env.REACT_APP_REDIRECT_URI) {
        // TODO: hardcode
        setTimeout(() => {
          window.location.href = `${process.env.REACT_APP_REDIRECT_URI}/?token=${token}`
        }, 2000)
      }
    }
    check()
  }, [token, setToken]);

  return (
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
