import eyeLogo from '../../assets/eye_logo.png'
import pc from '../../assets/pc.png'

import './Info.scss';

const Info = ({ text }: { text?: string }) => {

  const goToHomepage = () => {
    if (!process.env.REACT_APP_HOME_PAGE) {
      return
    }
    window.location.href = process.env.REACT_APP_HOME_PAGE
  }

  return (
    <div className="info_box">
      <div className="info_title" onClick={goToHomepage}>Olsights Ltd</div>
      <div className="logo">
        <img alt="logo" src={eyeLogo} onClick={goToHomepage} />
      </div>
      <div className="pc_box">
        <img alt="pc" src={pc} />
        <div className="name">Olsights Eye</div>
      </div>
      <div className="text">{text ? text : "Start visualising the world of clean energy & decarbonisation"}</div>
      <div className="footer_text">(c) <a href={process.env.REACT_APP_HOME_PAGE}>Olsights Ltd</a><br/>2023</div>
    </div>
  )
}


export {
  Info,
}
