import { useState, useEffect, useRef } from "react";

function useTimeout<T>(defaultValue: T | null, timeout: number = 3000): [T | null, React.Dispatch<React.SetStateAction<T | null>>] {
  const [value, setValue] = useState<T | null>(defaultValue);
  
  const valueTimer = useRef<string | null>(null);

  useEffect(() => {
    if (valueTimer.current) {
      clearTimeout(valueTimer.current)
    }
    if (value) {
      valueTimer.current = setTimeout(() => setValue(null), timeout) as unknown as string;
    }
    return () => {
      if (valueTimer.current) {
        clearTimeout(valueTimer.current)
      }
    }
  }, [timeout, value]);


  return [value, setValue];
};

export {
  useTimeout,
}
