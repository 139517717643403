import axios from 'axios';

enum ApiPaths {
  Login = 'users/login/',
  Signup = 'users/me/',
  Check = 'users/me/',
  ResetPassword = 'users/password_reset/',
  ConfirmResetPassword = 'users/password_reset/confirm',
}

interface ErrorPayload {
  error: true,
  text: string
}

function isError(test: any): test is ErrorPayload {
    return test.error && test.text;
}

function ErrorCatcher(data: any): ErrorPayload {
  if (data['non_field_errors'] && Array.isArray(data['non_field_errors'])) {
    return { error: true, text: data['non_field_errors'][0] }
  }
  return { error: true, text: data['text'] || 'Unknown Error' }
}

class API {
  baseURL = process.env.REACT_APP_BACKEND_BASE_URL
  headers = {
    Authorization: `Token ${localStorage.getItem('token')?.slice(1, -1)}`
  }

  async post(url: string, data?: Record<string, any>) {
    try {
      const result = await axios.post(this.baseURL + url, data)
      return result;
    } catch (e: any) {
      const result = ErrorCatcher(e?.response?.data)
      return result;
    }
  }

  async get(url: string, raw: string = '') {
    try {
      const result = await axios.get(this.baseURL + url + raw, { headers: this.headers })
      return result;
    } catch (e) {
      return e;
    }
  }
}

const ApplicationAPI = new API();

export {
  ApplicationAPI,
  ApiPaths,
  ErrorCatcher,
  isError,
}
