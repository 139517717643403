import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { Field } from '../../common/field/Field';

import { ApiPaths, ApplicationAPI, isError } from '../../api/api';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTimeout } from '../../hooks/useTimeout';

import './Login.scss';
import logo from '../../assets/logo.png';
import eyeLogo from '../../assets/eye_logo.png'
import { isValidEmail } from '../../common/utils/helpers';
import { GoogleButton, LinkedinButton } from '../social/Social';
import { ForgetPasswordPopup, ResetPasswordPopup } from '../popup/Popup';
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Info } from '../Info/Info';

const Login = () => {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [, setToken] = useLocalStorage<string>('token');
  const [error, setError] = useTimeout<string>(null);
  const [message, setMessage] = useTimeout<string>(null);
  const [show, setShow] = useState<boolean>(false);

  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const [resetPayload, setResetPayload] = useState<{ email?: string, token?: string, password?: string}>({});

  const handleClosePopup = () => {
    setShow(false)
  }

    const handleCloseResetPasswordPopup = () => {
    setShowResetPassword(false)
    navigate("/")
  }

  const onSubmit = async (data: any) => {
    const res: any = await ApplicationAPI.post(ApiPaths.Login, data)
    if (isError(res)) {
      setError(res.text);
      return;
    }
    const token = res?.data?.token;
    setMessage(res?.data?.message);
    setToken(token || '');
    if (token && process.env.REACT_APP_REDIRECT_URI) {
      setTimeout(() => {
        window.location.href = `${process.env.REACT_APP_REDIRECT_URI}/?token=${token}`
      }, 2000)
    }
  };

  const goToHomepage = () => {
    if (!process.env.REACT_APP_HOME_PAGE) {
      return
    }
    window.location.href = process.env.REACT_APP_HOME_PAGE
  }

  const moveToSignupPage = () => {
    navigate("/signup")
  }

  useEffect(() => {
    const email = queryParameters.get("email")
    const token = queryParameters.get("email")
    if (email && token) {
      setResetPayload({ email: email, token: token })
      setShowResetPassword(true);
    }
  }, [queryParameters])

  return (
    <>
      {message && <div className="alert-box"><Alert>{message}</Alert></div>}
      {error && <div className="alert-box"><Alert variant="danger">{error}</Alert></div>}
    <Container className="row clear" fluid>
      <Col lg={6} className="d-none d-lg-block m-0 p-0">
        <Info text="Continue visualising the world of clean energy & decarbonisation" />
      </Col>
      <Col lg={6} className="align-items-center centered">
        <Col lg="10" md="8" xs="11" className="button_box d-none d-lg-flex">
          <div>Not yet registered?</div>
          <Button size="lg" variant="primary" onClick={moveToSignupPage}>
            Sign up
          </Button>
        </Col>
        <div className="logo d-block d-lg-none" onClick={goToHomepage}>
          <img alt="logo" src={eyeLogo} />
        </div>
        <div className="mobile_title d-block d-lg-none">Olsights Eye</div>
        <div className="mobile_description d-block d-lg-none">Continue visualising the world of clean energy & decarbonisation</div>
        <Col lg="10" md="8" xs="11" className="mx-auto top_buffer">
          <h1 className="d-grid form_title">Login</h1>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Field label="Email Address" placeholder="Email" register={register("email", { required: true, validate: isValidEmail })} required />
            <ErrorMessage className="error" as="span" errors={errors} name="username" message="Provide valid email" />
            <Field label="Password" placeholder="Password" type="password" register={register("password", { required: true })} required />
            <div className="mb-2 d-flex flex-row">
              <p className="link" onClick={() => setShow(true)}>Forget password?</p>
            </div>
            <div className="d-grid">
              <Button size="lg" variant="primary" type="submit">
                Login
              </Button>
            </div>
            <div className="mt-2 flex-row d-flex d-lg-none">
              <div>Not registered in Olsightseye?</div>
              <a className="mx-2" href="/signup">Sign up</a>
            </div>
          </Form>
          <div className="auth-row">
            <GoogleButton />
            <LinkedinButton />
          </div>
        </Col>
        <Col lg="10" className="bottom_logo d-none d-lg-block">
          <img className="logo" src={logo} onClick={goToHomepage} alt="logo" />
        </Col>
        <Col lg="10" className="bottom_text d-block d-lg-none">
          <div>
            (c) <a href={process.env.REACT_APP_HOME_PAGE}>Olsights Ltd</a><br/>2023
          </div>
        </Col>
      </Col>
      <ForgetPasswordPopup show={show} handleClose={handleClosePopup} />
      <ResetPasswordPopup show={showResetPassword} payload={resetPayload} handleClose={handleCloseResetPasswordPopup} />
    </Container>
    </>
  )
}

export {
  Login
}