import { ErrorMessage } from '@hookform/error-message';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Form } from 'react-router-dom';
import { ApiPaths, ApplicationAPI, isError } from '../../api/api';
import { Field } from '../../common/field/Field';
import { isValidEmail, isValidPassword } from '../../common/utils/helpers';
import Alert from 'react-bootstrap/Alert';

import './Popup.scss';
import { useTimeout } from '../../hooks/useTimeout';

const Popup = ({ show, handleClose }: { show: boolean, handleClose: () => void }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="title">Verify Your Email</Modal.Title>
      </Modal.Header>
        <Modal.Body className="body">
          <div>Please check your email for a link to verify your email address.</div>
          <div className="my-4">Once verified, you'll be able to continue</div>
          {/* <div>Didn't receive an email? <a href="">Resend</a></div> */}
        </Modal.Body>
      </Modal>
  )
}

const ForgetPasswordPopup = ({ show, handleClose }: { show: boolean, handleClose: () => void }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [error, setError] = useTimeout<string>(null);

  const onSubmit = async (data: any) => {
    const result = await ApplicationAPI.post(ApiPaths.ResetPassword, data);
    if (isError(result)) {
      setError(result.text);
      return;
    }
    handleClose();
  }

  return (
    <>
      {error && <div className="alert-box" style={{ zIndex: 2050 }}><Alert variant="danger">{error}</Alert></div>}
      <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="title">Reset Password</Modal.Title>
      </Modal.Header>
        <Modal.Body className="body full">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Field label="Email Address" placeholder="Email" register={register("email", { required: true, validate: isValidEmail })} required />
            <ErrorMessage className="error" as="span" errors={errors} name="username" message="Provide valid email" />
            <div className="d-grid mt-4">
              <Button size="lg" variant="primary" type="submit">
                Send
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

const ResetPasswordPopup = ({ show, payload, handleClose }: { show: boolean, payload: any, handleClose: () => void }) => {
  const { register, handleSubmit, getValues, formState: { errors } } = useForm();
  const [error, setError] = useTimeout<string>(null);

  const onSubmit = async (data: any) => {
    const result = await ApplicationAPI.post(ApiPaths.ConfirmResetPassword, { ...payload, ...data })
    if (isError(result)) {
      setError(result.text);
      return;
    }
    handleClose();
  }

  const isSamePassword = (): string | boolean => {
    const passwords = getValues(['password', 'confirmPassword'])
    if (passwords[0] !== passwords[1]) {
      return 'Passwords do not match'
    }
    return true
  }

  return (
    <div>
      {error && <div className="alert-box" style={{ zIndex: 2050 }}><Alert variant="danger">{error}</Alert></div>}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="title">Reset Password</Modal.Title>
        </Modal.Header>
          <Modal.Body className="body full">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Field label="Password" placeholder="Password" type="password" register={register("password", { required: true, validate: isValidPassword })} required />
              <ErrorMessage errors={errors} name="password" render={({ message }) => <span className="error">{message}</span>} />
              <Field label="Confirm Password" placeholder="Password" type="password" register={register("confirmPassword", { required: true, validate: isSamePassword })} required />
              <ErrorMessage errors={errors} name="confirmPassword" render={({ message }) => <span className="error">{message}</span>} />
              <div className="d-grid mt-4">
                <Button size="lg" variant="primary" type="submit">
                  Reset
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
    </div>
  )
}

export {
  Popup,
  ForgetPasswordPopup,
  ResetPasswordPopup,
}
