import { useState } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';

import { Field } from '../../common/field/Field';
import { ErrorMessage } from '@hookform/error-message';
import { Popup } from '../popup/Popup';
import { ApiPaths, ApplicationAPI, isError } from '../../api/api';
import { useTimeout } from '../../hooks/useTimeout';

import './Signup.scss';
import logo from '../../assets/logo.png';
import eyeLogo from '../../assets/eye_logo.png'
import { isValidEmail, isValidPassword } from '../../common/utils/helpers';
import { GoogleButton, LinkedinButton } from '../social/Social';
import { Info } from '../Info/Info';

const Signup = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, getValues, formState: { errors } } = useForm();

  const [show, setShow] = useState(false);
  const [error, setError] = useTimeout<string>(null);

  const handleClosePopup = () => {
    setShow(false)
    navigate("/")
  }

  const onSubmit = async (data: any) => {
    if (!data.acceptService) {
      setError('You should accept our Terms and Conditions')
      return
    } 
    const res = await ApplicationAPI.post(ApiPaths.Signup, data);
    if (isError(res)) {
      setError(res.text);
      return;
    }
    setShow(true)
  }

  const goToHomepage = () => {
    if (!process.env.REACT_APP_HOME_PAGE) {
      return
    }
    window.location.href = process.env.REACT_APP_HOME_PAGE
  }

  const isSamePassword = (): string | boolean => {
    const passwords = getValues(['password', 'confirmPassword'])
    if (passwords[0] !== passwords[1]) {
      return 'Passwords do not match'
    }
    return true
  }

  const moveToLoginPage = () => {
    navigate("/")
  }

  return (
    <>
      {error && <div className="alert-box"><Alert variant="danger">{error}</Alert></div>}
      <Container className="row clear" fluid>
      <Col lg={6} className="d-none d-lg-block m-0 p-0">
        <Info />
      </Col>
      <Col lg={6} xs={12} className="align-items-center centered overflowed">
        <Col lg="10" md="8" xs="11" className="button_box d-none d-lg-flex">
          <div>Already have an account?</div>
          <Button size="lg" variant="primary" onClick={moveToLoginPage}>
            Login
          </Button>
        </Col>
        <div className="logo d-block d-lg-none" onClick={goToHomepage}>
          <img alt="logo" src={eyeLogo} />
        </div>
        <div className="mobile_title d-block d-lg-none">Olsights Eye</div>
        <div className="mobile_description d-block d-lg-none">Start visualising the world of clean energy & decarbonisation</div>
        <Col lg="10" md="8" xs="11" className="mx-auto">
        <h1 className="d-grid title top_buffer">Sign Up</h1>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field label="Full Name" placeholder="Full name" register={register("username", { required: true })} required />
          <Field label="Email Address" placeholder="Email" register={register("email", { required: true, validate: isValidEmail })} required />
          <ErrorMessage className="error" as="span" errors={errors} name="email" message="Provide valid email" />
          <Field label="Company" placeholder="Company" register={register("company")} />
          <Field label="Position" placeholder="Position" register={register("position")} />
          <Field label="Area of Interest " placeholder="Area of Interest " register={register("areaOfInterest")} />
          <Field label="Password" placeholder="Password" type="password" register={register("password", { required: true, validate: isValidPassword })} required />
          <div className="suggest">The password must be at least 8 characters long.</div>
          <ErrorMessage errors={errors} name="password" render={({ message }) => <span className="error">{message}</span>} />
          <Field label="Confirm Password" placeholder="Password" type="password" register={register("confirmPassword", { required: true, validate: isSamePassword })} required />
          <ErrorMessage errors={errors} name="confirmPassword" render={({ message }) => <span className="error">{message}</span>} />
          <Field label={<p>I agree with Olsights Eye <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_DATA_POLICY_URL}>Data Policy</a> and <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_TERMS_OF_SERVICE_URL}>Terms and Conditions</a></p>} type="checkbox" register={register("acceptService", { required: true })} />
          <ErrorMessage className="error-checkbox" as="span" errors={errors} name="acceptService" message="You need to accept Terms and Conditions" />
          <div className="d-grid mt-2">
            <Button size="lg" variant="primary" type="submit">
              Sign Up
            </Button>
          </div>
          <div className="mt-2 flex-row d-flex d-lg-none">
            <div>Already have an account?</div>
            <a className="mx-2" href="/">Login</a>
          </div>
          <div className="auth-row">
            <GoogleButton />
            <LinkedinButton />
          </div>
        </Form>
      </Col>
        <Col lg="10" className="bottom_logo d-none d-lg-block">
          <img className="logo" src={logo} onClick={goToHomepage} alt="logo" />
        </Col>
        <Col lg="10" className="bottom_text d-block d-lg-none">
          <div>
            (c) <a href={process.env.REACT_APP_HOME_PAGE}>Olsights Ltd</a><br/>2023
          </div>
        </Col>
      </Col>
      <Popup show={show} handleClose={handleClosePopup} />
    </Container>
    </>
  )
}

export {
  Signup
}