const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';

const scope = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile'
].join(' ');


const params = {
  response_type: 'code',
  client_id: process.env.REACT_APP_GOOGLE_API_KEY || '',
  redirect_uri: `${process.env.REACT_APP_BACKEND_BASE_URL}users/get_access_token/social/google-oauth2/`,
  prompt: 'select_account',
  access_type: 'offline',
  scope
};

const urlParams = new URLSearchParams(params).toString();

const AUTH_LINKS = {
  GOOGLE: `${googleAuthUrl}?${urlParams}`,
  LINKEDIN: `https://www.linkedin.com/oauth/v2/authorization?scope=r_emailaddress r_liteprofile&response_type=code&redirect_uri=${process.env.REACT_APP_BACKEND_BASE_URL}users/linkedin_login/&client_id=${process.env.REACT_APP_LINKED_IN_API_KEY}`,
}


const GoogleButton = () => {
  const auth = () => {
    window.location.href = AUTH_LINKS.GOOGLE
  }

  return (
    <div className="google-btn" onClick={auth}>
      <div className="google-icon-wrapper">
        <img alt="google" className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
      </div>
      <p className="btn-text">Sign in with Google</p>
    </div>
  )
}


const LinkedinButton = () => {
  const auth = () => {
    window.location.href = AUTH_LINKS.LINKEDIN
  }

  return (
    <div className="sig" onClick={auth}>
      <div className='ico-wrapper'>
        <div className='ico'>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
          </svg>
        </div>
      </div>
      <p className="btn-text">Sign in with LinkedIn</p>
    </div>
  )
}

export {
  GoogleButton,
  LinkedinButton,
}
