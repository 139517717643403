import Form from 'react-bootstrap/Form';
import { UseFormRegisterReturn } from "react-hook-form";

import './Field.scss';

interface FieldProps {
  label: string | React.ReactNode,
  type?: string,
  placeholder?: string,
  register: UseFormRegisterReturn,
  required?: boolean,
}

const Field = ({ label, type = "text", placeholder, register, required = false }: FieldProps) => {

  const Textbox = () => {
    return (
      <>
        <Form.Label className="label">{label}<span className="required">{required && " *"}</span></Form.Label>
        <Form.Control className="form-control-sm" {...register} type={type} placeholder={placeholder} required={required} />
      </>
    )
  }

  const Checkbox = () => {
    return (<Form.Check className="form-control-sm check-block" {...register} type="checkbox" label={label} />)
  }

  const FieldByType = () => {
    switch (type) {
      case "text":
      case "password":
        return <Textbox />
      case "checkbox":
        return <Checkbox />
    }
  }

  return (
    <Form.Group className="mb-1">
      {FieldByType()}
    </Form.Group>
  )
}

export {
  Field,
}
