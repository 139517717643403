const isValidEmail = (email: string): boolean =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

const isValidPassword = (password: string): string | boolean => {
  if (password.length < 8) {
    return 'Password is too short (minimum 8 characters)'
  }
  return true
}

export {
  isValidEmail,
  isValidPassword,
}
