import { useState, useEffect, Dispatch } from "react";

function getStorageValue<T>(key: string, defaultValue?: T) {
  const saved = localStorage.getItem(key);
  if (!saved || saved === 'undefined') {
    return defaultValue || null;
  }
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export const useLocalStorage = <T extends unknown>(key: string, defaultValue?: T ): [T, Dispatch<T>] => {
  const [value, setValue] = useState<T>(() => {
    return getStorageValue<T>(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
